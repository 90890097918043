import React, { useState, useMemo } from "react"
import { graphql } from "gatsby"
import Gallery from "react-photo-gallery"
import Carousel, { ModalGateway, Modal } from "react-images"
import { GeneralPageLayout } from "../components/GeneralPageLayout"
import { css } from "@emotion/core"
import { fontSizes } from "../styles/constants"

export function GalleryPageTemplate(props) {
  const [imageIndexToView, setImageIndexToView] = useState(null)

  const handleImageClick = (event, { index }) => {
    setImageIndexToView(index)
  }

  const handleModalClose = () => {
    setImageIndexToView(null)
  }
  const photosList = useMemo(() => {
    return (
      props.photos &&
      props.photos.map((p, i) => {
        return {
          height: p.height,
          width: p.width,
          src: p.photo.childImageSharp
            ? p.photo.childImageSharp.fluid.src
            : p.photo,
          key: i.toString(),
        }
      })
    )
  }, [props.photos])
  return (
    <GeneralPageLayout title={props.title}>
      {(photosList && (
        <Gallery
          photos={photosList}
          onClick={handleImageClick}
          direction="column"
        />
      )) || (
        <div
          css={css`
            height: 60vh;
            font-size: ${fontSizes.title.default};
          `}
        >
          <h1>Képek Hamarosan...</h1>
        </div>
      )}
      <ModalGateway>
        {imageIndexToView !== null ? (
          <Modal onClose={handleModalClose}>
            <Carousel currentIndex={imageIndexToView} views={photosList} />
          </Modal>
        ) : null}
      </ModalGateway>
    </GeneralPageLayout>
  )
}

function GalleryPage(props) {
  const { frontmatter } = props.data.markdownRemark
  return <GalleryPageTemplate {...frontmatter} />
}

const styles = {
  img: css`
    background-repeat: no-repeat;
    background-attachment: fixed;
  `,
}

export default GalleryPage

export const galleryPageQuery = graphql`
  query GalleryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        photos {
          photo {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          width
          height
        }
      }
    }
  }
`
